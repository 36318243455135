body {
  font-family: Georgia, "Times New Roman", Times, serif;
  line-height: 1.6;
  font-size: 20px;
}

* {
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #111;
}

.inline {
  display: inline;
}

.bold {
  font-weight: bold;
}

.name {
  font-size: 23px;
}

.nameSub {
  font-size: 17px;
}

.AppContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #b0b0b0;
  margin: 80px;
  padding: 100px 20px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.basics img {
  width: 50%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  padding: 8px 0;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid #b0b0b0;
  border-bottom: 2px solid #b0b0b0;
  color: #333;
}

.footer > div {
  display: flex;
  gap: 4px;
}

.LanguageSwitcher button {
  background: none;
  border: none;
  color: #333;
  font-size: 20px;
  font-weight: lighter;
  font-family: "Varela Round", sans-serif;
  letter-spacing: 3px;
  text-align: center;
}

.LanguageSwitcher button:hover {
  font-weight: bold;
}

.LanguageSwitcher button.selected {
  font-weight: bolder;
  text-decoration: underline;
  text-underline-offset: 8px;
}

.ProfileLink {
  width: 40px;
  height: 40px;
}

.verticalLine {
  font-size: 20px;
  color: #333;
  font-weight: bold;
}

.basicsSummary {
  text-align: justify;
  padding: 80px 160px;

}

.imgContainer {
  display: flex;
  justify-content: center;
}

.nameStyle {
  font-family: "Varela Round", sans-serif;
  font-size: 48px;
  letter-spacing: 3px;
  line-height: 1.05;
  text-align: center;
  margin-top: 20px;
}

.labelStyle {
  font-family: "Varela Round", sans-serif;
  letter-spacing: 3px;
  font-size: 27px;
  text-align: center;
}

.works,
.projects,
.skills,
.languages,
.education,
.contact {
  padding: 0px 160px 80px 160px;
  text-align: justify;
  width: 100%;
  min-height: 400px;
}

.languages,
.education,
.contact {
  text-align: center;
  justify-content: center;
}

.line {
  max-width: 320px;
  margin: 40px auto;
  height: 1.6px;
  background: #b0b0b0;
}

.basicContact {
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
}

.cursor {
  cursor: pointer;
}

.tabsContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.tabsLine {
  flex-grow: 1;
  max-width: 400px;
  margin: -40px auto 0 auto;
  height: 1.6px;
  background: #b0b0b0;
}

.tabs {
  padding: 50px 0 80px 0;
  display: flex;
  gap: 12px;
}

.tabs button {
  cursor: pointer;
  background-color: transparent;
  outline: none;
  width: 100%;
  height: 40px;
  padding: 0px;
  margin-bottom: 10px;
  font-family: "Varela Round", sans-serif;
  font-size: 24px;
  color: #b0b0b0;
  border: none;
}

.tabs button.active,
.tabs button:hover {
  color: #111;
  border-bottom: 2px solid #b0b0b0;
}

.contact label {
  display: block;
  text-align: right;
  padding-right: 20px;
  width: 150px;
}

.contact .row {
  justify-content: center;
  padding-bottom: 20px;
}

input,
textarea {
  width: 320px;
  font-size: 17px;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.submitButtonContainer {
  width: 320px;
  text-align: left;
}

.submitButton {
  background-color: #333;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.submitButton:hover {
  border: none;
  background-color: #b0b0b0;
}

.menuTabs {
  display: flex;
  flex-direction: column;
}

.menuButton {
  border-top: 2px solid #b0b0b0;
  border-bottom: 2px solid #b0b0b0;
  border-left: none;
  border-right: none;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  width: 90%;
  height: 40px;
  padding: 0px;
  font-family: "Varela Round", sans-serif;
  font-size: 18px;
  color: #b0b0b0;
  margin: 20px 0;
}

@media screen and (max-width: 1200px) {
  .body {
    font-size: 17px;
  }

  .AppContainer {
    border: none;
    margin: 20px 0px;
    padding: 0px;
  }

  .nameStyle {
    font-size: 30px;
  }

  .labelStyle {
    font-size: 20px;
  }

  .basics img {
    height: 80%;
    width: 80%;
    padding-top: 10px;
  }

  .works,
  .projects,
  .skills,
  .languages,
  .education,
  .contact,
  .basicsSummary {
    padding: 40px 20px;
  }

  .tabs {
    padding: 0px;
  }

  .tabs button {
    height: auto;
    margin: 0px;
    font-size: 18px;
  }

  .tabs button.active,
  .menuButton.active {
    color: #333;
    border: none;
  }
}